import React from 'react'
import Header from "../inc/Header";
import ScrollToTop from "react-scroll-to-top";

function Refund() {
  return (
    <div>
      <Header/>
            <br/>
            <br/>
            <br/>
            <br/>
            <ScrollToTop smooth top="20" color="Darkblue" />
      <div className="album py-4">
    
    <div className="container">

        <div className="row">

        <h3 className="card-text mb-4"> <center> <b> Terms of return/refund</b></center></h3>
        <p style={{color:"Gray"}} className="card-text"> <b> 1. </b>For Wi-Fi Services</p>
        <p style={{color:"Gray"}} className="card-text"> <b> 2. </b>For Internet Services</p>
        <p style={{color:"Gray"}} className="card-text"> <b> 3.</b> Payment will be refunded within 
        72 hours if the internate package is not shown for any reason after booking the Internet package.</p>
        <p style={{color:"Gray"}} className="card-text"> <b> 4.</b> If the payment is canceled due to any technical reason, we will refund the payment online</p>
        </div>
        </div>
    </div>
    <br/>
    </div>
  )
}

export default Refund
