
  

import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
    apiKey: "AIzaSyCdNKgnZBoKFxPgkKukkVM_qkyfV61wdwI",
    authDomain: "karimpurnetzone.firebaseapp.com",
    projectId: "karimpurnetzone",
    storageBucket: "karimpurnetzone.appspot.com",
    messagingSenderId: "776072916672",
    appId: "1:776072916672:web:bc462575a36a58b3bfc725",
    measurementId: "G-VRJ7B3YJQS"
 
};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;