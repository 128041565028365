import React from 'react'
import Header from "../inc/Header";
import ScrollToTop from "react-scroll-to-top";

function Privacy() {
  return (
    <div>
        <Header/>
            <br/>
            <br/>
            <br/>
            <br/>
            <ScrollToTop smooth top="20" color="Darkblue" />
      <div className="album py-4">
    
    <div className="container">

        <div className="row">

        <h5 className="card-text mb-4"> Thank you for using karimpurnetzone.com! Your privacy is important to us. Please read the following statement to learn about our information gathering and dissemination practices</h5>
        <h4 style={{color:"Gray"}} className="card-text"> Privacy Policy</h4>
        <p style={{color:"Gray"}} className="card-text mb-4"><b>Karimpur Netzone Private Limited </b>(here in after referred to as "us" "we" or "our") is committed to respecting your privacy and complying with any applicable data protection and privacy laws. If you do submit personal information by signing up to login to the Netzone
         website /App services for example to order products, services etc., you can be assured that we will use your personal information only to support your continuing relationship with us. We are providing this Privacy Policy Statement to help you understand how we collect, use and protect your information when you visit our web sites and when you generally use our products and services. We wish to help you make informed decision. So please take a few moments to read the sections below and learn how we may use your personal information. You should read this notice in conjunction with the terms and conditions of use for websites and any separate terms and conditions issued with our product/service Contract.</p>
         <h4 style={{color:"Gray"}} className="card-text">Personal Information Collection</h4>
         <p style={{color:"Gray"}} className="card-text mb-4">We endeavour to collect and use your personal information only with your knowledge and consent and typically when you order and subsequently use products and services, make customer enquiries, register for information or other services, request product information, submit a job application or when you respond to communications from us (such as questionnaires or surveys). The type of personal information we may collect could include, for example, your name and postal address, date of birth, gender, telephone and fax numbers, email address, and credit/ debit card information; lifestyle and other information collected on registration or through surveys. If you choose to provide us with personal information it will be used in support of
          the intended purposes stated at the time at which it was collected, and subject to any preferences indicated by you.</p>
          <h4 style={{color:"Gray"}} className="card-text">Non-personally identifying Information</h4>
          <p style={{color:"Gray"}} className="card-text mb-4">We may also collect non-personally identifying information about your visit to our web sites /App based on your browsing (click-stream) activities. This information may include the pages you browse and products and services viewed or ordered for example. This helps us to manage and develop our sites better, to provide you with a more enjoyable, customised service and experience in the future, and to help us develop and deliver better products and services tailored to your individual interests and needs.</p>
          <h4 style={{color:"Gray"}} className="card-text">How will we use your information?</h4>
          <p style={{color:"Gray"}} className="card-text mb-4">We may use your information for a number of purposes which includes: processing your orders and managing and administering your account; delivering any services, products or information requested by you; responding to complaints or account enquiries; administering debt recoveries; verifying your identity when required (you may lose your password or security information for example, and we may then need to ask you for other 'identifiable' information to protect your data from unauthorised access. We may also undertake market and product analysis based on your use of our services and products and contact you with information about new developments, products, services and special offers by post, telephone and automated means such as mobile text message (SMS), Email, Fax, Pager, WAP and the world wide web. We may also tell you about the products and services of carefully selected third parties and allow you to receive advertising and marketing information from those selected third parties without passing control of your personal information to the third party. For example we may send you information with your bill, or you may simply receive an advertisement on your personal web and WAP page according to any preferences indicated by you and/or according to your interests. This helps us to make you aware of products and services that we may have specially negotiated for our own customers and which may be of individual interest to you. You acknowledge that by
            providing data to us, you consent to the processing of your data in accordance with this Privacy Policy Statement.</p>
            <h4 style={{color:"Gray"}} className="card-text">Information Security</h4>
            <p style={{color:"Gray"}} className="card-text mb-4">We recognise that our customers are increasingly concerned about how companies protect personal information from misuse and abuse and about privacy in general. We are constantly reviewing and enhancing our technical, physical and managerial procedures and rules to protect your personal data from unauthorised access, accidental loss and/ or destruction. We use industry standard secure sockets layer (SSL) technology, for example, to encrypt sensitive information such as your credit card and other financial information. Please be aware that communications over the Internet, such as emails/webmails, are not secure unless they have been encrypted. Your communications may route through a number of countries before being delivered - this is the nature of the World Wide Web/Internet.
             We cannot accept responsibility for any unauthorised access or loss of personal information that is beyond our control.</p>
             <h4 style={{color:"Gray"}} className="card-text">Queries and Complaints :</h4>
             <p style={{color:"Gray"}} className="card-text mb-3">We are committed to protect your personal information collected and processed by us and look forward to your continued support for the same. In case of any feedback or concern regarding protection of 
             your personal information, or any privacy-related feedback or concerns you may contact <a href=" netzoneprivatelimited@gmail.com"> netzoneprivatelimited@gmail.com</a></p>
            </div>
            </div>
            </div>

    </div>
  )
}

export default Privacy
