import React from 'react';
import {Link} from 'react-router-dom';
import Header from '../inc/Header';
import ScrollToTop from "react-scroll-to-top";
// import ReactWhatsapp from "react-whatsapp";


import Googlemap from "../pages/Googlemap";


function Contact(props) {
    return (
      
<>
<Header/>
<ScrollToTop smooth top="20" color="Darkblue" />
<br/>
<br/>
{/* <ScrollToTop smooth top="100" color="purple" /> */}
{/* <img src={Single} className="img-fluid" width="1350" height="600"   alt="Single" />  */}



<div className="contactcolor">

<div className="maashyama">
        <div className="container py-2">

            <div className="row">
<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><Link to="/" >Home</Link></li>
    <li class="breadcrumb-item"><Link to="/products">Service</Link></li>
    <li class="breadcrumb-item"><Link to="/">About Us</Link></li>
    <li class="breadcrumb-item active" aria-current="page">Contact</li>
  </ol>
</nav>
</div>
</div>
</div>
<div className="album py-3">
        
        <div className="container">

            <div className="row justify-content-center">
       
            <div className="col-md-5" >

            <h3 style={{color:"Gray"}} className="card-text"> <b> KARIMPUR NETZONE PRIVATE LIMITED  </b></h3>
            <h6 className="card-text mb-2">   CIN : U64200WB2022PTC256037</h6>
            <p className="card-text mb-2"> Plot No.1103, Ramkrishnapally </p>
           
                                <p className="card-text mb-2"> Karimpur, Near Bus Stand, Nadia</p>
                                <p className="card-text mb-2">West Bengal | 741152 | India </p>
                              
                                <p className="card-text"> Karimpur to Krishnagar Road<br/>Copyright © Karimpur NetZone Private Limited</p>
                         
                              
                                
                                <div className="d-flex justify-content-between align-items-center">
                               
                        
                            </div>
                               
                            </div>
                            <div className="col-md-4 mt-6" >
                        
                                <h6 className="card-text mb-2"> <b>CONTACT </b></h6>
                                <a href="tel: +91 8343958575"  className="btn btn- btn-light mb-2">   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z"/>
</svg>  +91 8343958575 </a>
<br/>
<a href="mailto: netzoneprivatelimited@gmail.com" className="card-text mb-2"> netzoneprivatelimited@gmail.com</a>

{/* <ReactWhatsapp number="+91  9732723744" class="btn btn-outline-light" message="I am Interested"> Whatsapp Inquiry</ReactWhatsapp> */}
                 <div className="d-flex justify-content-between align-items-center">
                         
                            </div>
                               
                            </div>
                            <div className="col-md-3 mt-6" >
                            <h6 className="card-text "> <b>FOR PRESS</b></h6>
                            <a href="tel: +91 7872485015"  className="btn btn- btn-light mb-2 ">   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z"/>
</svg> +91 7872485015</a>

                              
                           
                                
                                <div className="d-flex justify-content-between align-items-center">
                         
                            </div>
                               
                            </div>
                            </div>
                            </div>
                            </div>


                
               
              
                 <br/>
                 
                 </div>   
          <Googlemap/>     


</>
        
        );
    }
    
    export default Contact;