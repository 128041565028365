import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";

// import PaginationOld from './PaginationOld';
import {db} from '../../firebase'
import Header from '../inc/Header';

// import ScrollToTop from "react-scroll-to-top";

// import {Helmet} from "react-helmet";

import ScrollToTop from "react-scroll-to-top";

function Allproduct (props) {
    const loadScript = (src) => {
        return new Promise((resovle) => {
          const script = document.createElement("script");
          script.src = src;
    
          script.onload = () => {
            resovle(true);
          };
    
          script.onerror = () => {
            resovle(false);
          };
    
          document.body.appendChild(script);
        });
      };
      
    const displayRazorpay = async (amount) => {
        const res = await loadScript(
          "https://checkout.razorpay.com/v1/checkout.js"
        );
    
        if (!res) {
          alert("You are offline... Failed to load Razorpay SDK");
          return;
        }
    
        const options = {
          key: "rzp_test_hyx6ybGu1SPbze",
          currency: "INR",
          amount: amount * 100,
          name: "Karimpur NetZone Pvt. Ltd.",
          description: "Thanks for purchasing",
          image:
            "https://mern-blog-akky.herokuapp.com/static/media/logo.8c649bfa.png",
    
          handler: function (response) {
            alert(response.razorpay_payment_id);
            alert("Payment Successfully");
          },
          prefill: {
            name: "Karimpur NetZone",
          },
        };
    
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      };
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
      const [showPerPage, setShowPerPage] = useState(60);
      const [pagination, setPagination] =useState({
          start: 0,
          end: showPerPage,
      });
  
      const onPaginationChange = (start,  end) =>{
          setPagination({start: start, end: end});
      };
      const [searchTerm, setsearchTerm] = useState("");
      const [product_details, setProduct_details] = useState([])
      const navigate = useNavigate()
  
      const product_detailsCollectionRef = collection( db, "product_details");
      useEffect(() => {
  
          const getProduct_details = async () => {
              const data = await getDocs(product_detailsCollectionRef);
              setProduct_details(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
          }
  
          getProduct_details()
      }, [])
    return (
      
<>
<Header/>
<ScrollToTop smooth top="20" color="Darkblue" />
<br/>
<br/>


<br/>
<br/>
<br/>
<h1 className="mb-2" style={{textAlign: "center", color: "Gray"}}><b >KARIMPUR NETZONE PRIVATE LIMITED</b></h1>

<div className="album py-3 mb-2">
        
            <div className="container">

                <div className="row">
               
                <div className="col-md-6">
                        
                        <div className="card-body">
                        <input type="text" class="form-control" placeholder="Search " name="Search" 
                        onChange ={(e) => {
                          setsearchTerm(e.target.value);
                        }}
                        />
                                <div className="d-flex justify-content-between align-items-center">
                                   
                                    
                            

                            </div>
                          
                    
                        </div>

                      
                    </div>
             
                    </div>
                  
                    </div>
                    </div>
                    <div className="album py-0">
    
    <div className="container">

        <div className="row">
<nav aria-label="breadcrumb">
<ol className="breadcrumb">
<li className="breadcrumb-item"><Link to="/" >Home</Link></li>
<li className="breadcrumb-item"><Link to="/contact" >Contact Us</Link></li>
<li className="breadcrumb-item"><Link to="/" >About Us</Link></li>
<li className="breadcrumb-item"><Link to="/Phonesign" >Number Verify</Link></li>
<li className="breadcrumb-item active" aria-current="page">Services</li>
</ol>
</nav>
</div>
</div>
</div>
<div className="album py-4">
        
        <div className="container">

            <div className="row">
            {product_details.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } else if (
        val.item.toLowerCase().includes(searchTerm.toLowerCase()) ||
        val.description.toLowerCase().includes(searchTerm.toLowerCase())
     
       
  
      ){
        return val;
      }
    }).slice(pagination.start, pagination.end).map((Karimpurnetzone) => { return (
       
            <div className="col-md-4 mt-8" >
                        <div className="card mb-4 border-White box shadow">
                               
                  <div className="card-header">
                        <h4 style={{color: "Darkblue", textAlign: "center"}}> {Karimpurnetzone.plan}</h4>
                              </div>
                        <img  className="card-img-top" src={Karimpurnetzone.display_picture}   alt="FreshFood" />
                  
                            <div className="card-body">
                               
                                <h5  style={{color: "Gray", textAlign: "center"}} className="card-text"> <b>{Karimpurnetzone.item}</b></h5>
                                
                           
                                <h5  style={{color:"Gray", textAlign: "center"}}  className="card-text mb-3"> ₹{Karimpurnetzone.description}</h5>
                              
                                <h5 style={{color:"Gray", textAlign: "center"}}  className="card-text" >₹{Karimpurnetzone.price}/-</h5>
                               
                                <div class="col-md-12 text-center">
                                <button onClick={() => displayRazorpay(Karimpurnetzone.price)} className="btn btn-outline-primary mb-3" > REACHERGE </button>
                                </div>
   
            
    <center>
    <a href="tel: +91 8343958575"  className="btn btn- btn-secondary mb-2">    Help Line Number  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-plus-fill" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM12.5 1a.5.5 0 0 1 .5.5V3h1.5a.5.5 0 0 1 0 1H13v1.5a.5.5 0 0 1-1 0V4h-1.5a.5.5 0 0 1 0-1H12V1.5a.5.5 0 0 1 .5-.5z"/>
</svg> </a> </center>

                                       
                                    
                              
                          
                            </div>
                            </div>
                               
                            </div>
                                )})} 
                            </div>
                            {/* <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={product_details.length}
                /> */}
                            </div>
                            </div>

</>
        
        );
    }
    
    export default Allproduct;