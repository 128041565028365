
import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Main from "./components/pages/Main";
import Phonesign from "./components/pages/Phonesign";
import Products from "./components/pages/Products";
import Contact from "./components/pages/Contact";
import Footer from "./components/inc/Footer";
import Linkage from "./components/pages/Linkage";
import Privacy from "./components/pages/Privacy";
import Terms from "./components/pages/Terms";
import Refund from "./components/pages/Refund";
import UserAuthContextProvider from './contexts/UserAuthContext';

function App() {
  return (
   
    <Router>
       <UserAuthContextProvider>
        <div>
          
          <Routes>
          <Route axact path="/" element={<Main/>}/>
           <Route axact path="/Phonesign" element ={<Phonesign/>}/>
            <Route axact path="/Products" element ={<Products/>}/>
            <Route axact path="/contact" element ={<Contact/>}/>
            <Route axact path="/Linkage" element ={<Linkage/>}/>
            <Route axact path="/Privacy" element ={<Privacy/>}/>
            <Route axact path="/Refund" element ={<Refund/>}/>
            <Route axact path="/Terms&Conditions" element ={<Terms/>}/>

          </Routes>
          <Footer/>
 
    </div>
    </UserAuthContextProvider>
    </Router>
  );
}

export default App;

