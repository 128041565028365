import React from 'react'
import Header from "../inc/Header";
import ScrollToTop from "react-scroll-to-top";

function Terms() {
  return (
    <div>
       <Header/>
            <br/>
            <br/>
            <br/>
            <br/>
            <ScrollToTop smooth top="20" color="Darkblue" />
      <div className="album py-4">
    
    <div className="container">

        <div className="row">

        <h3 className="card-text mb-4"> <center> <b> Terms and Conditions</b></center></h3>
        <p style={{color:"Gray"}} className="card-text"> 
The contents of this website may include inaccuracies or typographical errors. Changes and updates to the website may be made at any time. Packages, prices and service offerings are liable to change at the instance of Karimpur netzone . Inclusion and deletion of channels and services is at the sole discretion of Karimpur Netzone. No Refund of fixed duration pack is allowed and Cancellation of payment transaction is allowed.
</p>
<p style={{color:"Gray"}} className="card-text">This website may contain links to other web sites operated by third parties. You acknowledge that the website owner is providing these links to you only as a convenience.</p>
<p style={{color:"Gray"}} className="card-text">You further acknowledge that the website owner is not responsible for any content of any such linked site or any further link contained in a link site, or any changes or updates to such sites. You specifically agree that the website owner shall not be responsible for unauthorized access to or alteration of your transmissions or data, any material or data sent or received or not sent or received, or any transactions entered into through the website. You specifically agree that the website owner is not responsible or liable for any threatening, defamatory, obscene, offensive or illegal content or conduct of any other party or any infringement of another's rights, including intellectual property rights. In no event shall Karimpur Netzone be liable for any direct, indirect, punitive, incidental, special, consequential damages or any damages whatsoever including, without limitation, damages for loss of use, data or profits, arising out of or in any way connected with the use or performance of the website, with the delay or inability to use the website or related services, the provision of or failure to provide services, or for any information, software, products, 
  services and related graphics obtained through the website, or otherwise arising out of the use of the website.</p>
  <p style={{color:"Gray"}} className="card-text">Karimpur Netzone does not endorse in anyway any advertisers/ contents of advertisers on its webpages.
    Please therefore verify the veracity of all information on your own before placing any reliance on such information.</p>
    <p style={{color:"Gray"}} className="card-text">Installation, Activation and Package Charges are to be paid in advance</p>
    <p style={{color:"Gray"}} className="card-text">Service Taxes will be charged extra GST @ 18%.</p>

    </div>
    </div>
    </div>
    </div>
  )
}

export default Terms
