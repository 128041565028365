import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Alert } from "react-bootstrap";
import Header from '../inc/Header';
import GoogleButton from 'react-google-button';
import { Button } from "react-bootstrap";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useUserAuth } from '../../contexts/UserAuthContext';

const Phonesign = () => {
  const { logIn, googleSignIn, user } = useUserAuth();
  const [error, setError] = useState("");
  const [number, setNumber] = useState("");
  const [flag, setFlag] = useState(false);
  const [otp, setOtp] = useState("");
  const [result, setResult] = useState("");
  const { setUpRecaptcha } = useUserAuth();
  const navigate = useNavigate();

  const getOtp = async (e) => {
    e.preventDefault();
    console.log(number);
    setError("");
    if (number === "" || number === undefined)
      return setError("Please enter a valid phone number!");
    try {
      const response = await setUpRecaptcha(number);
      setResult(response);
      setFlag(true);
    } catch (err) {
      setError(err.message);
    }
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    setError("");
    if (otp === "" || otp === null) return;
    try {
      await result.confirm(otp);
      navigate("/Products");
    } catch (err) {
      setError(err.message);
    }
  };


  
  const handleGoogleSignIn = async (e) => {
    e.preventDefault()
    try {
      if(await googleSignIn()){
        window.location.reload()
      
        // const newUser = {
        //   "fullname": user.displayName,
        //    "email":user.email,
        //    "mobile":null,
        //    "password":null,
        //  }
        //  addDoc(userCollectionRef, newUser)

      
      navigate("/Products")
      }
    }catch (err) {
      setError(err.message)
    }
  }
  return (
    <>
    <Header/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <div className="album py-0">
    
    <div className="container">

        <div className="row">
    <h5 style={{ color: "Gray"}}><b> #নাম্বার বা গুগল ভেরিফাই না করলে আপনি আমাদের ওয়েবসাইটে রিচার্জ করতে পারবেন না ও দেখাবে না...</b></h5>
    </div>
    </div>
    </div>
<br/>
<div className="album py-0">
    
    <div className="container">

        <div className="row">
<nav aria-label="breadcrumb">
<ol className="breadcrumb">
<li className="breadcrumb-item"><Link to="/" >Home</Link></li>
<li className="breadcrumb-item"><Link to="/contact" >Contact Us</Link></li>
<li className="breadcrumb-item"><Link to="/" >About</Link></li>
<li className="breadcrumb-item active" aria-current="page">Number Verify</li>
</ol>
</nav>
</div>
</div>
</div>
    <div class="p-3 mb-2 bg-secondary text-white">
    <div className="album py-4">
        
        <div className="container">

            <div className="row justify-content-center">
              <div className="col-md-5">
      <div className="p-4 mb-1 box">
     
        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={getOtp} style={{ display: !flag ? "block" : "none" }}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            
            <PhoneInput
              defaultCountry="IN"
              value={number}
              onChange={setNumber}
              placeholder="Enter Phone Number"
            />

            <div id="recaptcha-container"></div>
          </Form.Group>
          <center>
          <div className="button-right ">
            <Link to="/">
              
              <Button variant="info">Cancel</Button>
            </Link>
            &nbsp;
            <Button type="submit" variant="outline-light" >
              Send Otp
            </Button>
          </div></center>
        </Form>
</div>
</div>
</div>
        <Form onSubmit={verifyOtp} style={{ display: flag ? "block" : "none" }}>
          <Form.Group className="mb-3" controlId="formBasicOtp">
            <Form.Control
              type="otp"
              placeholder="Enter OTP"
              onChange={(e) => setOtp(e.target.value)}
            />
          </Form.Group>
          <div className="button-right">
            <Link to="/">
              <Button variant="secondary">Cancel</Button>

            </Link>
            &nbsp;
            <Button type="submit" variant="outline-info">
              Verify
            </Button>
          </div>
        </Form>
        </div>
      </div>
      <center className='mt-1'>
      <GoogleButton onClick = { handleGoogleSignIn }  className='g-btn' type='dark'></GoogleButton>
       </center>
       <br/>
     </div>
     
    </>
  );
};

export default Phonesign;