import React from 'react'
import {Link} from "react-router-dom";
function Header() {
  return (
    <div>
       

      <div className="fixed-top">
      
      <div class="p-1 mb-0 bg-secondary text-white"> 
      <div className="album py-1">
        
        <div className="container">

            <div className="row justify-content-end">
                   +91 8343 95 8575    
                  
      </div>
      </div>
      </div>
      </div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light shadow">
  <div className="container-fluid">
    <Link to="/" className="navbar-brand me-5" >Karimpur NetZone</Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <Link to="/" className="nav-link active" aria-current="page" >HOME</Link>
        </li>
          <li className="nav-item">
          <Link to="/" className="nav-link active" aria-current="page" >ABOUT</Link>
        </li>
        <li className="nav-item">
          <Link to="/Linkage" className="nav-link active" aria-current="page" >LINKAGE</Link>
        </li>
        <li className="nav-item">
          <Link to="/Products" className="nav-link active" aria-current="page" >SERVICES</Link>
        </li>
        <li className="nav-item">
          <Link to="/contact" className="nav-link active" aria-current="page" >CONTACT</Link>
        </li>
     
     
    
      </ul>
    
        <Link to="/phonesign">
        <button className="btn btn-outline-success" type="submit">NUMBER VERIFY </button>
        </Link>
      
    </div>
  </div>
</nav>
</div>
    </div>
  )
}

export default Header
