import React from 'react';
import {Link} from 'react-router-dom';

function Googlemap(props) {
    return (
<>
<div className="album py-4">
        
        <div className="container">

            <div className="row">
       
            
 
           
         
<iframe className="gmap_iframe" width="1100" height="436"  frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=KALPANA SPEEDLINK কল্পনা স্পিডলিংক&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
</div>

</div>
</div>


</>
);
}

export default Googlemap;